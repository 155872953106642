//Core
import React from "react"

//Style
import "./index.scss"
import { Parallax } from "react-scroll-parallax"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

//i18
import { withTranslation } from "react-i18next"

const Footer = props => {
  const { t } = props
  return (
    <footer>
      <div className="contacts" id="contacts-anchor">
        <div className="bg"></div>
        <AnimatedOnScroll
          screenOffset={-200}
          animationInDuration={500}
          animationIn="bounceInLeft"
        >
          <Parallax
            y={[-90, 90]}
            styleInner={{
              fontFamily: "gazeta",
              fontStyle: "italic",
              color: "#2c2e36",
            }}
            className="title"
          >
            {t("contact")}
          </Parallax>
        </AnimatedOnScroll>
        <AnimatedOnScroll animationIn="slideInUp">
          <Parallax y={[-70, 90]} className="items-wrapper">
            <div className="item" data-language="publishing">
              {t("publish")}
            </div>
            <div className="item">
              <a className="link" href="tel:380637577480">
                +38 (063) 757-74-80
              </a>
            </div>
            <div className="item">
              <a className="link" href="mailto:office@sbook.com.ua">
                E-mail: office@sbook.com.ua
              </a>
            </div>
          </Parallax>
        </AnimatedOnScroll>

        <a
          href="https://ru-ru.facebook.com/MariaMinyailo/"
          target="_blank"
          rel="noopener noreferrer"
          className="face facebook-white"
        ></a>
        <a
          href="https://www.instagram.com/maria.miniailo/"
          target="_blank"
          rel="noopener noreferrer"
          className="face instagram-white"
        ></a>

        <div className="item">
          <a
            href="https://typerror.co/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            style={{ fontSize: 11 }}
            className="link"
          >
            Developed by <b>TYPE ERROR</b>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default withTranslation("main")(Footer)
