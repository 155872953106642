//Core
import React, { useState } from "react"

//Images
import book_history from "../../images/book_history.png"
import book_detektiv from "../../images/book_detektiv.png"
import book_demon from "../../images/book_2.png"
import book_happy from "../../images/book_3.png"
import book_children from "../../images/book_4.png"
import { Parallax } from "react-scroll-parallax"

//i18
import { withTranslation } from "react-i18next"

//Styles
import "./index.scss"

//Components
import Carousel from "../Carousel"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

const BookLeft = props => {
  const [presentation, changePresentation] = useState(null)
  const [show, changeVision] = useState(false)

  const { t } = props

  const showPresentation = index => {
    changeVision(true)
    changePresentation(index)
    console.log("showPresentation", index)
  }

  const closePresentation = () => {
    changeVision(false)
  }

  return (
    <section className="main">
      <section className="third-block">
        <Parallax y={[1, -1]} className="book-list reverse">
        <div className="book reverse">
            <div className="book-info">
              <Parallax y={[0, -5]} className="bg"></Parallax>
              <Parallax y={[0, -35]} className="book-img">
                <img
                  src={book_detektiv}
                  alt="Пропавшая без вести - Презентация книги Мария Миняйло"
                />
              </Parallax>
        
              <Parallax
                y={[0, -70]}
                styleInner={{
                  fontFamily: "gazeta",
                  fontStyle: "italic",
                  fontWeight: "lighter",
                }}
                className="book-letter"
              >
                {t("book_1_letter")}
              </Parallax>
            </div>
            <Parallax y={[0, -20]} className="book-description">
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                screenOffset={100}
                animationIn="fadeInRight"
              >
                <div className="title">{t("book_detective_title")}</div>

                <h4 className="description">{t("book_detective_subtitle")}
                  <br />
                  <br />
                  {t("book_detective_main")}</h4>
                <a
                  className="description-action"
                  href="https://sbook.com.ua/ru/home/906-propavshaya-bez-vesti.html?fbclid=IwAR1-sYZsVAuEFofJgDFB18SjqDct3FQyF-ElBiPY-FDCCAFYfGd5vlvjzBI"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {t("buy")}
                </a>
              </AnimatedOnScroll>
            </Parallax>
          </div>
        </Parallax>
          

        <Parallax y={[1, -1]} className="book-list">
          <div className="book">
            <div className="book-info">
              <Parallax y={[0, -15]} className="bg"></Parallax>
              <Parallax y={[0, -20]} className="book-img">
                <img
                  src={book_children}
                  alt={t("book_4_title") + t("name") + t("surname")}
                />
              </Parallax>
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                animationIn="fadeInRight"
              >
                <div
                  className="info-action"
                  onClick={() => showPresentation(3)}
                >
                  {t("presentation")}
                </div>
              </AnimatedOnScroll>

              <Parallax
                y={[0, -100]}
                styleInner={{ fontFamily: "gazeta", fontStyle: "italic" }}
                className="book-letter"
              >
                {t("book_4_letter")}
              </Parallax>
            </div>
            <Parallax y={[0, -20]} className="book-description">
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                screenOffset={150}
                animationIn="fadeInLeft"
              >
                <div className="title" data-language="secondBookTitle">
                  {t("book_4_title")}
                </div>
                <div className="description" data-language="secondBookDesc">
                  <h3> {t("book_4")} </h3>
                  <h3 style={{ textAlign: "center" }}>
                    {t("book_4_subtitle")}
                    <br />
                    {t("book_4_main")}
                  </h3>
                </div>
                <a
                  className="description-action"
                  href="http://sbook.com.ua/ru/home/738-deti-grada.html?search_query=Mariya+Minyajlo&results=2"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {t("buy")}
                </a>
              </AnimatedOnScroll>
            </Parallax>
          </div>
          <div className="book">
            <div className="book-info">
              <Parallax y={[0, -15]} className="bg"></Parallax>
              <Parallax y={[0, -25]} className="book-img">
                <img
                  src={book_history}
                  alt="Истории старого города Презентация книги Мария Миняйло"
                />
              </Parallax>
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                animationIn="fadeInLeft"
              >
                <div
                  className="info-action"
                  onClick={() => showPresentation(0)}
                >
                  {t("presentation")}
                </div>
              </AnimatedOnScroll>

              <Parallax
                y={[0, -70]}
                styleInner={{
                  fontFamily: "gazeta",
                  fontStyle: "italic",
                  fontWeight: "lighter",
                }}
                className="book-letter"
              >
                {t("book_1_letter")}
              </Parallax>
            </div>
            <Parallax y={[0, -20]} className="book-description">
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                screenOffset={100}
                animationIn="fadeInRight"
              >
                <div className="title">{t("book_1_title")}</div>
                <h2 className="description">{t("book_1")} </h2>
                <a
                  className="description-action"
                  href="http://sbook.com.ua/ru/home/462-istorii-starogo-goroda.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {t("buy")}
                </a>
              </AnimatedOnScroll>
            </Parallax>
          </div>
          <div className="book">
            <div className="book-info">
              <Parallax y={[0, -15]} className="bg"></Parallax>
              <Parallax y={[0, -20]} className="book-img">
                <img
                  src={book_demon}
                  alt={t("book_2_title") + t("name") + t("surname")}
                />
              </Parallax>
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                animationIn="fadeInRight"
              >
                <div
                  className="info-action"
                  onClick={() => showPresentation(1)}
                >
                  {t("presentation")}
                </div>
              </AnimatedOnScroll>

              <Parallax
                y={[0, -100]}
                styleInner={{ fontFamily: "gazeta", fontStyle: "italic" }}
                className="book-letter"
              >
                {t("book_2_letter")}
              </Parallax>
            </div>
            <Parallax y={[0, -20]} className="book-description">
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                screenOffset={150}
                animationIn="fadeInLeft"
              >
                <div className="title" data-language="secondBookTitle">
                  {t("book_2_title")}
                </div>
                <h2 className="description" data-language="secondBookDesc">
                  {t("book_2")}
                </h2>
                <a
                  className="description-action"
                  href="http://sbook.com.ua/ru/home/260-dialogi-s-dyavolom.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {t("buy")}
                </a>
              </AnimatedOnScroll>
            </Parallax>
          </div>
          <div className="book">
            <div className="book-info">
              <Parallax y={[0, -15]} className="bg"></Parallax>
              <Parallax y={[0, -20]} className="book-img">
                <img
                  src={book_happy}
                  alt={t("book_3_title") + t("name") + t("surname")}
                />
              </Parallax>
              <AnimatedOnScroll
                animationInDuration={500}
                animateOnMoun={false}
                screenOffset={150}
                animationIn="fadeInLeft"
              >
                <div
                  className="info-action"
                  onClick={() => showPresentation(2)}
                >
                  {t("presentation")}
                </div>
              </AnimatedOnScroll>

              <Parallax
                y={[0, -100]}
                styleInner={{ fontFamily: "gazeta", fontStyle: "italic" }}
                className="book-letter"
              >
                {t("book_3_letter")}
              </Parallax>
            </div>
            <Parallax y={[0, -20]} className="book-description">
              <AnimatedOnScroll screenOffset={150} animationIn="fadeInRight">
                <div className="title">{t("book_3_title")}</div>
                <h2 className="description" id="happy">
                  {t("book_3")}
                </h2>
                <a
                  className="description-action"
                  href="http://sbook.com.ua/ru/home/172-chetyre-ottenka-schastya.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {t("buy")}
                </a>
              </AnimatedOnScroll>
            </Parallax>
          </div>
        </Parallax>
      </section>
      {show ? (
        <Carousel
          show={true}
          presentation={presentation}
          close={closePresentation}
        ></Carousel>
      ) : null}
    </section>
  )
}

export default withTranslation("main")(BookLeft)
