//Core
import React, { useState, useEffect } from "react"

//Style
import "./index.scss"

import AwesomeSlider from "react-awesome-slider"
import AwesomeSliderStyles from "react-awesome-slider/src/styled/cube-animation/"

const presentation1 = [
  "/static/images/presentation1/0.jpg",
  "/static/images/presentation1/1.jpg",
  "/static/images/presentation1/2.jpg",
  "/static/images/presentation1/3.jpg",
  "/static/images/presentation1/4.jpg",
  "/static/images/presentation1/5.jpg",
  "/static/images/presentation1/6.jpg",
  "/static/images/presentation1/7.jpg",
  "/static/images/presentation1/8.jpg",
  "/static/images/presentation1/9.jpg",
  "/static/images/presentation1/10.jpg",
  "/static/images/presentation1/11.jpg",
  "/static/images/presentation1/12.jpg",
  "/static/images/presentation1/13.jpg",
  "/static/images/presentation1/14.jpg",
  "/static/images/presentation1/15.jpg",
  "/static/images/presentation1/16.jpg",
  "/static/images/presentation1/17.jpg",
  "/static/images/presentation1/18.jpg",
  "/static/images/presentation1/19.jpg",
  "/static/images/presentation1/20.jpg",
  "/static/images/presentation1/21.jpg",
  "/static/images/presentation1/22.jpg",
  "/static/images/presentation1/23.jpg",
]
const presentation4 = [
  "/static/images/presentation4/1.jpg",
  "/static/images/presentation4/2.jpg",
  "/static/images/presentation4/3.jpg",
  "/static/images/presentation4/4.jpg",
  "/static/images/presentation4/5.jpg",
  "/static/images/presentation4/6.jpg",
  "/static/images/presentation4/7.jpg",
  "/static/images/presentation4/8.jpg",
  "/static/images/presentation4/9.jpg",
  "/static/images/presentation4/10.jpg",
  "/static/images/presentation4/11.jpg",
  "/static/images/presentation4/12.jpg",
  "/static/images/presentation4/13.jpg",
  "/static/images/presentation4/14.jpg",
  "/static/images/presentation4/15.jpg",
  "/static/images/presentation4/16.jpg",
  "/static/images/presentation4/17.jpg",
]
const presentation2 = [
  "/static/images/presentation2/1.jpg",
  "/static/images/presentation2/2.jpg",
  "/static/images/presentation2/3.jpg",
  "/static/images/presentation2/4.jpg",
  "/static/images/presentation2/5.jpg",
  "/static/images/presentation2/6.jpg",
  "/static/images/presentation2/7.jpg",
  "/static/images/presentation2/8.jpg",
  "/static/images/presentation2/9.jpg",
  "/static/images/presentation2/10.jpg",
  "/static/images/presentation2/11.jpg",
  "/static/images/presentation2/12.jpg",
  "/static/images/presentation2/13.jpg",
  "/static/images/presentation2/14.jpg",
  "/static/images/presentation2/15.jpg",
  "/static/images/presentation2/16.jpg",
  "/static/images/presentation2/17.jpg",
  "/static/images/presentation2/18.jpg",
  "/static/images/presentation2/19.jpg",
  "/static/images/presentation2/20.jpg",
  "/static/images/presentation2/21.jpg",
  "/static/images/presentation2/22.jpg",
  "/static/images/presentation2/23.jpg",
  "/static/images/presentation2/24.jpg",
  "/static/images/presentation2/25.jpg",
  "/static/images/presentation2/26.jpg",
  "/static/images/presentation2/27.jpg",
  "/static/images/presentation2/28.jpg",
  "/static/images/presentation2/29.jpg",
  "/static/images/presentation2/30.jpg",
  "/static/images/presentation2/31.jpg",
  "/static/images/presentation2/32.jpg",
  "/static/images/presentation2/33.jpg",
  "/static/images/presentation2/34.jpg",
  "/static/images/presentation2/35.jpg",
  "/static/images/presentation2/0.jpg",
]
const presentation3 = [
  "/static/images/presentation3/1.jpg",
  "/static/images/presentation3/2.jpg",
  "/static/images/presentation3/3.jpg",
  "/static/images/presentation3/4.jpg",
  "/static/images/presentation3/5.jpg",
  "/static/images/presentation3/6.jpg",
  "/static/images/presentation3/7.jpg",
  "/static/images/presentation3/8.jpg",
  "/static/images/presentation3/9.jpg",
  "/static/images/presentation3/10.jpg",
  "/static/images/presentation3/11.jpg",
  "/static/images/presentation3/12.jpg",
  "/static/images/presentation3/13.jpg",
  "/static/images/presentation3/14.jpg",
  "/static/images/presentation3/0.jpg",
]

const Slider = ({ show, presentation, close }) => {
  const [count, changeCount] = useState(0)
  const [list, changeList] = useState(presentation1)

  useEffect(() => {
    if (presentation === 0) {
      changeList(presentation1)
    }
    if (presentation === 1) {
      changeList(presentation2)
    }
    if (presentation === 2) {
      changeList(presentation3)
    }
    if (presentation === 3) {
      changeList(presentation4)
    }
  })

  const increaseCount = () => {
    const newCount = list.length > count + 1 ? count + 1 : 0

    changeCount(newCount)
  }
  const decreaseCount = () => {
    const newCount = count - 1 < 0 ? list.length - 1 : count - 1

    changeCount(newCount)
  }

  return (
    <>
      <div className={`slider ${show ? "show" : ""}`} onClick={close}></div>
      <div className="carousel">
        <div className="close" onClick={close}></div>
        <div className="img-wrapper" id="img-wrapper">
          <AwesomeSlider fillParent={true} cssModule={AwesomeSliderStyles}>
            {list.map(item => (
              <div key={item} data-src={item}></div>
            ))}
          </AwesomeSlider>
        </div>
      </div>
    </>
  )
}

export default Slider
