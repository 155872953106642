import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",
    whitelist: ["ru", "uk"],
    resources: {
      ru: {
        main: {
          title: "Писательница Мария Миняйло",
          description:
            "Персональный сайт Марии Миняйло - современной писательницы, которая является автором удивительных книг, с которыми вы можете ознакомиться подробнее, а также просмотреть фото презентаций книг.",
          name: "Мария",
          surname: "Миняйло",
          author: "писательница",
          about: "Обо мне",
          library: "Библиотека",
          contacts: "Kонтакты",
          qoute:
            "Книга – это история. Хорошая книга – это история, в которой каждый найдет что-то свое. Свое настроение, свое прошлое, свой опыт или даже свое будущее. Художественная литература прекрасна тем, что не учит и не навязывает. Читатель волен сам решать какие эмоции ему испытывать: любить или ненавидеть. Хорошая книга – это эмоции. Всегда.",
          book_1:
            "За каждым окном скрыта своя история. Где-то живет старый кот Барсик, с надорванным ухом и коротким толстым хвостом, где-то над учебниками склонилась тихая школьница Зоря, а вот мужчина с орлиным профилем вспоминает свою первую любовь. А там владелец овощного магазина, жизнь которого изменила случайная встреча, и смешливая секретарша с букетом хризантем… Старый город знает множество историй, добрых и очень настоящих, которые стоят того, чтобы их рассказать",
          book_1_title: "Истории старого города",
          book_1_letter: " ",
          book_2:
            "«Диалоги с Дьяволом» – не о Боге и Дьяволе, тут нет религии и догм. Это книга о невероятных перипетиях, о том, что даже облезлый бродячий котенок пришел в этот мир с определенной целью. О том, что безысходности нет, а если она и наступает, тостаканчик виски с яблочным соком – лучшее лекарство.",
          book_2_title: "Диалоги с Дьяволом",
          book_2_letter: " ",
          book_3:
            "Легкие, слегка ироничные, местами грустные, но очень жизненные рассказы о том, что счастье – это личный выбор и какой у него оттенок – решать только вам! Четыре уникальные истории, в которых все непременно сложится хорошо. Или практически... Решать, как всегда, вам.",
          book_3_title: "Четыре оттенка счастья",
          book_3_letter: " ",

          book_4_title: "Дети Града",
          book_4_letter: " ",
          book_4: "Книга посвящается всем рано повзрослевшим детям… ",
          book_4_subtitle:
            "Роман «Дети Града» рассказывает о воспитанниках интерната «Серая Ива» города М., судьбы которых внезапно пересекаются…",
          book_4_main:
            "Витька, Славик, Аким и Мива не в силах принять разницу между мечтами об идеальной жизни и реальным миром и в один день сбегают на поиски загадочного Града — места, где нет правил и где все дети счастливы. «Дети Града» — история о том, как непросто быть ребенком в жестоком мире взрослых. Это история о каждом, кто рано повзрослел, так и не успев стать взрослым.",

          book_detective_title: "Пропавшая без вести",
          book_detective_subtitle: "Однажды утром Тамара собрала вещи и уехала в неизвестном направлении. Родные думают, что она сбежала с любовником. Однако друг детства, Соломон Александрович, уверен, что она попала в беду. Вместе с сыном он начинает собственное расследование, которое приводит их в небольшой отель «Вилла Мария».",
          book_detective_main: "По воле капризов погоды мужчины оказываются отрезанными от внешнего мира. И пока Соломон Алек­сандрович пытается выяснить, знал ли кто-то из гостей отеля Тамару, один из постояльцев умирает. Все выгля­дит как сердечный приступ, но так ли это?..",
          
          
          contact: "КОНТАКТЫ",
          publish: "«Издательство «САММИТ-КНИГА»",
          buy: "Купить",
          presentation: "Презентация книги",
        },
      },
      uk: {
        main: {
          title: "Письменниця Марія Міняйло",
          description:
            "Персональний сайт Марії Міняйло — сучасної письменниці , яка є авторкою дивовижних книг, з якими ви можете ознайомитися детальніше, а також переглянути фото презентацій книг.",
          name: "Марія",
          surname: "Міняйло",
          author: "письменниця",
          about: "Про мене",
          library: "Бібліотека",
          contacts: "Контакти",
          qoute:
            "Книга – це історія. Хороша книга – це історія, в якій кожен знайде щось своє. Свій настрій, своє минуле, свій досвід або, навіть, своє майбутнє. Художня література прекрасна тим, що не вчить і не нав'язує. Читач може сам вирішувати, які саме емоції йому відчувати: любити або ненавидіти. Хороша книга – це емоції. Завжди.",
          book_1:
            "За кожним вікном прихована своя історія. Десь живе старий кіт Барсік, з надірваним вухом ікоротким товстим хвостом, десь над підручниками схилилася тиха школярка Зоря, а ось чоловік з орлиним профілем згадує своє перше кохання. А там власник овочевогo магазина, життя якого змінила випадкова зустріч, і сміхотлива секретарка з букетом хризантем... Старе місто знає безліч історій, добрих і дуже справжніх, які варті того, щоб їх розповісти.",
          book_1_title: "Історії старого міста",
          book_1_letter: " ",
          book_2:
            "«Діалоги з Дияволом» — не про Бога і Диявола, тут немає релігії і догм. Це книга про неймовірні перипетії, про те, що навіть облізлий бродячий кошеня прийшов у цей світ з певною метою. Про те, що безвиході немає, а якщо вона і настає, то келих віскі з яблучним соком — найкращі ліки.",
          book_2_title: "Діалоги з Дияволом",
          book_2_letter: " ",
          book_3:
            "Легкі, злегка іронічні, місцями сумні, але дуже життєві розповіді про те, що щастя —це особистий вибір і який у нього відтінок —вирішувати тільки вам! Чотири унікальні історії, в яких все неодмінно складеться добре. Або практично... Вирішувати, як завжди, вам.",
          book_3_title: "Чотири відтінки щастя",
          book_3_letter: " ",
          book_4: "Книга присвячується всім рано подорослішавшим дітям...",
          book_4_subtitle:
            "Роман «Діти Града» розповідає про вихованців інтернату «Сіра Іва» міста М., долі яких раптово перетинаються ...",
          book_4_main:
            "Вітька, Славик, Акім і Міва не в силах прийняти різницю між мріями про ідеальне життя і реальним світом і в один день втікають на пошуки загадкового граду - місця, де немає правил і де всі діти щасливі.«Діти Града» - історія про те, як непросто бути дитиною в жорстокому світі дорослих. Це історія про кожного, хто рано подорослішав, так і не встигнувши стати дорослим.",
          book_4_title: "Діти Града",
          book_4_letter: " ",

          book_detective_title: "Зникла безвісти",
          book_detective_subtitle: "Одного ранку Тамара зібрала речі і поїхала в невідомому напрямку. Рідні думають, що вона втекла з коханцем. Однак друг дитинства, Соломон Олександрович, впевнений, що вона потрапила в біду. Разом із сином він розпочинає власне розслідування, яке приводить їх у невеликий готель «Вілла Марія».",
          book_detective_main: "З волі примх погоди чоловіки виявляються відрізаними від зовнішнього світу. І поки Соломон Олександрович намагається з'ясувати, чи знав хтось із гостей готелю Тамару, один із постояльців вмирає. Все виглядає як серцевий напад, але чи так це?..",

          contact: "КОНТАКТИ",
          publish: "«Видавництво «САММІТ-КНИГА»",
          buy: "Купити",
          presentation: "Презентація книги",
        },
      },
    },

    // have a common namespace used around the full app
    ns: ["main"],
  })

export default i18n
