import React from "react"
import Helmet from "react-helmet"

//i18
import { withTranslation } from "react-i18next"
import i18n from "i18next"

const CURRENT_URL = "current_url"

function SEO(props) {
  const lang = i18n.language
  const { t } = props

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={t("title")}
      meta={[
        {
          name: `description`,
          content: t("description"),
        },
        {
          property: `og:title`,
          content: t("title"),
        },
        {
          property: `og:description`,
          content: t("description"),
        },
        {
          property: "og:type",
          content: "books.author",
        },
        {
          property: "og:image",
          content: "/icons/icon-512x512.png",
        },
        {
          property: "books:book",
          content:
            "http://sbook.com.ua/ru/home/462-istorii-starogo-goroda.html",
        },
        {
          property: "books:book",
          content: "http://sbook.com.ua/ru/home/260-dialogi-s-dyavolom.html",
        },
        {
          property: "books:book",
          content:
            "http://sbook.com.ua/ru/home/172-chetyre-ottenka-schastya.html",
        },
        {
          property: "books:official_site",
          content: CURRENT_URL,
        },
        {
          property: "og:url",
          content: CURRENT_URL,
        },
      ].concat()}
    />
  )
}

export default withTranslation("main")(SEO)
