//Cores
import React, { Component } from "react"

//Images
import circle from "../../images/icons/circle.svg"
import square from "../../images/icons/square.svg"
import circles from "../../images/icons/circles.svg"
import double_line from "../../images/icons/double_line.svg"
import border from "../../images/icons/border.svg"
import line from "../../images/icons/line.svg"
import triangle from "../../images/icons/triangle.svg"

//Styles
import "./index.scss"

export default class index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      color: 0,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll(event) {
    const scrollTop = document.documentElement.scrollTop
    const clientHeight = document.documentElement.clientHeight

    const rest = Math.floor((scrollTop / clientHeight) * 1.2) % 2

    this.setState({ color: rest })
  }
  render() {
    const { color } = this.state
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100vh",
          zIndex: 10,
        }}
      >
        <img
          className={`circle fly_icon ${color ? "orange" : "blue"}`}
          src={circle}
          alt="icon"
        ></img>
        <img
          className={`circles fly_icon ${color ? "orange" : "blue"}`}
          src={circles}
          alt="icon"
        ></img>
        <img
          className={`circles right fly_icon ${color ? "orange" : "blue"}`}
          src={circles}
          alt="icon"
        ></img>
        <img
          className={`double_line fly_icon ${color ? "orange" : "blue"}`}
          src={double_line}
          alt="icon"
        ></img>
        <img
          className={`border_square fly_icon ${color ? "orange" : "blue"}`}
          src={border}
          alt="icon"
        ></img>
        <img
          className={`line fly_icon ${color ? "orange" : "blue"}`}
          src={line}
          alt="icon"
        ></img>
        <img
          className={`triangle fly_icon ${color ? "orange" : "blue"}`}
          src={triangle}
          alt="icon"
        ></img>
        <img
          className={`triangle right fly_icon ${color ? "orange" : "blue"}`}
          src={triangle}
          alt="icon"
        ></img>
        <img
          className={`line right fly_icon ${color ? "orange" : "blue"}`}
          src={line}
          alt="icon"
        ></img>

        <img
          className={`square fly_icon ${color ? "orange" : "blue"}`}
          src={square}
          alt="icon"
        ></img>
        <img
          className={`square right fly_icon ${color ? "orange" : "blue"}`}
          src={square}
          alt="icon"
        ></img>
      </div>
    )
  }
}
