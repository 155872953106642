//Core
import React from "react"

//style
import "./style.scss"
import { Parallax } from "react-scroll-parallax"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

//i18next
import { withTranslation } from "react-i18next"

const Qoute = ({ t }) => {
  return (
    <AnimatedOnScroll
      screenOffset={200}
      animationInDuration={500}
      animationIn="fadeInLeft"
    >
      <section className="quote_section">
        <Parallax y={[15, -10]}>
          <div className="quote">
            <div className="quote_img">
              <div className="marks_bg">
                <div className="marks"></div>
              </div>
            </div>

            <blockquote className="description">{t("qoute")}</blockquote>
            <h1 className="quote_footer">{t("name") + " " + t("surname")}</h1>
          </div>
        </Parallax>
      </section>
    </AnimatedOnScroll>
  )
}

export default withTranslation("main")(Qoute)
