//Core
import React, { Component } from "react"

//Styles
import "./animation.scss"
import { Parallax } from "react-scroll-parallax"
import i18n from "i18next"

//i18
import { withTranslation } from "react-i18next"

//itils
import jump from "jump.js"

class Header extends Component {
  state = {
    show: false,
    menu: 0,
    language: null,
  }
  componentDidMount() {
    setTimeout(() => {
      var hotbod = document.querySelector("header")

      function doStuff() {
        hotbod.className += " animate"
      }

      doStuff()
    }, 60)
    this.setState({
      language: i18n.language,
    })
  }

  handlerChangeLanguage = lng => {
    i18n.changeLanguage(lng)
    this.setState({ show: false, language: lng })
  }

  handlerJumpTo = (obj, count) => {
    jump(obj)
    this.setState({ show: false, menu: count })
  }
  handlerShowHamburger = () => {
    this.setState({ show: true })
  }

  handlerHideHamburger = () => {
    this.setState({ show: false })
  }

  render() {
    const { t } = this.props
    const { show, menu, language } = this.state

    return (
      <header>
        <div className="jumpToTop"></div>
        <nav style={{ position: "relative", zIndex: 10000 }}>
          <div
            className="hamburger"
            style={{ marginTop: "5%", marginRight: "5%" }}
            onClick={this.handlerShowHamburger}
          >
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
          </div>
          <div className={`navigation ${show ? "open" : ""}`}>
            <div className="languages">
              <div
                className={`language ${language === "uk" ? "active" : ""}`}
                onClick={() => this.handlerChangeLanguage("uk")}
              >
                УКР
              </div>
              <div
                className={`language ${language === "ru" ? "active" : ""}`}
                onClick={() => this.handlerChangeLanguage("ru")}
              >
                РУС
              </div>
            </div>
            <div
              className={`hamburger open`}
              onClick={this.handlerHideHamburger}
            >
              <div className="item"></div>
              <div className="item"></div>
              <div className="item"></div>
            </div>

            <div className="nav">
              <a
                className={`nav-item ${menu === 0 ? "active" : ""}`}
                onClick={() => this.handlerJumpTo(".jumpToTop", 0)}
              >
                {t("about")}
              </a>
              <a
                className={`nav-item ${menu === 1 ? "active" : ""}`}
                onClick={() => this.handlerJumpTo(".main", 1)}
              >
                {t("library")}
              </a>
              <a
                className={`nav-item ${menu === 2 ? "active" : ""}`}
                onClick={() => this.handlerJumpTo(".contacts", 2)}
              >
                {t("contacts")}
              </a>
            </div>
          </div>
        </nav>
        <div className="header" style={{ zIndex: -1 }}>
          <div style={{ zIndex: -1 }}>
            <Parallax y={[0, -200]} className="unbreakable_container">
              <div className="unbreakable">
                <span className="name">{t("name")} </span>
                <span className="surname"> {t("surname")}</span>
              </div>
              <div className="writer">{t("author")}</div>
            </Parallax>
          </div>
        </div>
        {/* <a
          href="https://ru-ru.facebook.com/MariaMinyailo/"
          ref="follow"
          target="_blank"
          rel="noopener noreferrer"
          className="social facebook"
        ></a>
        <a
          href="https://www.instagram.com/maria.miniailo/"
          ref="follow"
          target="_blank"
          rel="noopener noreferrer"
          className="social instagram"
        ></a> */}
      </header>
    )
  }
}

export default withTranslation("main")(Header)
