//Core
import React from "react"

//SEO
import Helmet from "react-helmet"

//Components
import Header from "../components/Header"
import Canvas from "../components/Canvas"
import Quote from "../components/Quote"
import Books from "../components/Books"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

//i18
import "../i18/i18next"

//Style
import "../styles/index.scss"
import "antd/dist/antd.css"
import { ParallaxProvider } from "react-scroll-parallax"

const Main = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:400,500,700,900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Person",
            name: "Мария Миняйло",
            jobTitle: "Украинская писательница/автор книг",
            address: {
              "@type": "PostalAddress",
              addressLocality: "Киев",
              addressRegion: "Украина.Киев",
            },
          })}
        </script>
      </Helmet>
      <SEO></SEO>
      <ParallaxProvider>
        <Canvas></Canvas>
        <Header></Header>
        <Quote></Quote>
        <Books></Books>
        <Footer></Footer>
      </ParallaxProvider>
    </>
  )
}

export default Main
